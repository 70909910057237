import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { getCourses, getCoursesPage ,getCourseById, getGeneralInfo} from "../../providers"

import { MainLayout } from "../../components/Layouts/MainLayout"
import { Hero2 } from "../../components/Hero2/index"
import ProgrammingCourses from "../../components/Programming/ProgrammingCourses"
import Brochure from "../../components/Programming/Brochure"

import "../../assets/css/global.css"

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  height: 100%;
`
const CoursesAvailable = () => {
  const [coursesPages, setCoursesPages] = useState([])
  const [availableCourses, setAvailableCourses] = useState([])

  const [generalInfo, setGeneralInfo] = useState({})


  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const _coursesPage = await getCoursesPage()
        setCoursesPages(_coursesPage[0])

        const _availableCourses = await getCourses({ is_available: true })
        
        const _generalInfo = await getGeneralInfo()
        setGeneralInfo(_generalInfo[0])


        
const datainput =[]
        _availableCourses.map((item)=>{

          for (let i=0; i <item.programs.length; i++ ){
            const aux = {
              init_date: item.programs[i]?.init_date,
              title: item.title,
              schedule: item.programs[i]?.schedule,
              days: item.programs[i]?.days,
              modality:item.programs[i]?.modality,
              instructor:{name:item.programs[i]?.instructor.name,cv:item.programs[i]?.instructor.cv},
              vacancies:item.programs[i]?.vacancies,
            }
            datainput.push(aux)
          }
/*
        if (item.programs.length <= 1){
          const aux = {
            init_date: item.init_date,
            title: item.title,
            schedule: item.programs[0]?.schedule,
            days: item.programs[0]?.days,
            modality:item.programs[0]?.modality,
            vacancies:item.programs[0]?.vacancies,
          }

          datainput.push(aux)
        }

        if (item.programs.length === 2){
          const aux = {
            init_date: item.programs[0]?.init_date,
            title: item.title,
            schedule: item.programs[0]?.schedule,
            days: item.programs[0]?.days,
            modality:item.programs[0]?.modality,
            vacancies:item.programs[0]?.vacancies,
          }

          datainput.push(aux)

          const aux2 = {
            init_date: item.programs[1]?.init_date,
            title: item.title,
            schedule: item.programs[1]?.schedule,
            days: item.programs[1]?.days,
            modality:item.programs[1]?.modality,
            vacancies:item.programs[1]?.vacancies,
          }

          datainput.push(aux2)

        }

        if (item.programs.length === 3){
          const aux = {
            init_date: item.programs[0]?.init_date,
            title: item.title,
            schedule: item.programs[0]?.schedule,
            days: item.programs[0]?.days,
            modality:item.programs[0]?.modality,
            vacancies:item.programs[0]?.vacancies,
          }

          datainput.push(aux)

          const aux2 = {
            init_date: item.programs[1]?.init_date,
            title: item.title,
            schedule: item.programs[1]?.schedule,
            days: item.programs[1]?.days,
            modality:item.programs[1]?.modality,
            vacancies:item.programs[1]?.vacancies,
          }

          datainput.push(aux2)

          const aux3 = {
            init_date: item.programs[2]?.init_date,
            title: item.title,
            schedule: item.programs[2]?.schedule,
            days: item.programs[2]?.days,
            modality:item.programs[2]?.modality,
            vacancies:item.programs[2]?.vacancies,
          }

          datainput.push(aux3)

        }

        if (item.programs.length === 4){
          const aux = {
            init_date: item.programs[0]?.init_date,
            title: item.title,
            schedule: item.programs[0]?.schedule,
            days: item.programs[0]?.days,
            modality:item.programs[0]?.modality,
            vacancies:item.programs[0]?.vacancies,
          }

          datainput.push(aux)

          const aux2 = {
            init_date: item.programs[1]?.init_date,
            title: item.title,
            schedule: item.programs[1]?.schedule,
            days: item.programs[1]?.days,
            modality:item.programs[1]?.modality,
            vacancies:item.programs[1]?.vacancies,
          }

          datainput.push(aux2)

          const aux3 = {
            init_date: item.programs[2]?.init_date,
            title: item.title,
            schedule: item.programs[2]?.schedule,
            days: item.programs[2]?.days,
            modality:item.programs[2]?.modality,
            vacancies:item.programs[2]?.vacancies,
          }

          datainput.push(aux3)

          const aux4 = {
            init_date: item.programs[3]?.init_date,
            title: item.title,
            schedule: item.programs[3]?.schedule,
            days: item.programs[3]?.days,
            modality:item.programs[3]?.modality,
            vacancies:item.programs[3]?.vacancies,
          }

          datainput.push(aux4)

        }*/
         
        })
      
        setAvailableCourses(datainput)

  
      } catch (error) {
        console.log(error)
      }
    }
    fetchCourses()
  }, [])




  return (
    <>
      <MainLayout>
        <Container>
          <Hero2 data={coursesPages} />
          <ProgrammingCourses data={availableCourses} />
          <Brochure data={coursesPages?.brochure_image} background="celeste" contact={generalInfo} banner={coursesPages} />
        </Container>
      </MainLayout>
    </>
  )
}

export default CoursesAvailable
